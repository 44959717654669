import React, {useRef, useState} from 'react';
import './Navbar1.scss';
import {Link} from "react-router-dom";
import {useLocation} from "react-router";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faBars} from "@fortawesome/free-solid-svg-icons";
import {iPadCheck, phoneCheck} from "./helpers/browserCheck";

const Navbar1 = () => {

    const location = useLocation();
    const [isMenuClicked, setMenuClick] = useState(false);
    let hamburgerMenuRef = useRef();

    const isActive = (location, path) => {
        if(location.pathname === path) {
            return { color: 'rgb(0,0,0)' }
        }

        // else {
        //     return {  color: '#bbb' }
        // }
    };

    const handleMenuClick = () => {
        setMenuClick(!isMenuClicked);
    };

    const bars = <FontAwesomeIcon icon={faBars} />;


    const hamburgerMenu = () => (
        <div className="toolbar" ref={hamburgerMenuRef}>

            <div className="nav-bar">
               <span className="nav-logo__name">
                   <Link to="/" className='logo-container'>
                        <div className="logo">NATASHA DESAI</div>
                    </Link>

                   <div className="hamburgermenu-button" onClick={handleMenuClick}>
                       {bars}
                   </div>
               </span>
            </div>

            { menuData.length && (
                <nav className={` hamburger-menu ${isMenuClicked ? `show` : ``}`}>
                    {/*<div className="close-menu">*/}
                    {/*    /!*<div>Welcome!</div>*!/*/}
                    {/*    <span onClick={() => setMenuClick(false)} className="close-button"><i className="fa fa-times"/></span>*/}
                    {/*</div>*/}

                    <ul className="menu-items">
                        { menuData.map((item) =>
                            <li key={item.label} className="menu-item"
                                // style={isActive(history, `${item.url}`)}
                            >
                                <Link to={item.url} className="menu-link">{item.label}</Link>
                            </li>
                        )}
                    </ul>
                </nav>
            )}
        </div>
    );


    return(
        <div className="Navbar1">
            {phoneCheck() ? hamburgerMenu() :
                <div className={iPadCheck() ? "navbar-items-container-with-logo-ipad" : "navbar-items-container-with-logo"}>
                    <Link to="/" className='logo-container'>
                        <div className="logo">NATASHA DESAI</div>
                    </Link>

                    <span className="navbar-items-container"> {/* Section 2 */}
                        <ul className="nav-menu-items">
                           { menuData.map((item) => (
                               <li key={item.id} className="menu-item">
                                   <Link to={item.url} className="menu-link"
                                       style={isActive(location, `${item.url}`)}
                                   >
                                       {item.label}
                                   </Link>

                               </li>
                           ))}
                       </ul>
                </span>
                </div>}
        </div>
    )
};

export default Navbar1;


const menuData = [
    {
        id: 1,
        label: 'Selected Work',
        url: '/',
        dropdown: false
    },
    {
        id: 2,
        label: 'Published Work',
        url: '/published-work',
        dropdown: false
    },
    {
        id: 3,
        label: 'About',
        url: '/about',
        dropdown: false
    },
    {
        id: 4,
        label: 'Contact',
        url: '/contact',
        dropdown: false
    },
];
