import React from 'react';
import './Contact.scss';
import Navbar1 from "./Navbar1";
import Footer from "./Footer";
import {phoneCheck} from "./helpers/browserCheck";

const Contact = () => {

    const contactInfo = () => (
        <div className="contact-info-container">
            <h1>Let's Talk!</h1>
            <div className={phoneCheck() ? "contact-mobile" : "contact"}>
                <div className="hr-text">
                    <hr className="hr"/>
                    <p> Contact </p>
                    <hr className="hr"/>
                </div>
                {/*<p><a href="tel:+919833243137">+91 98332 43137</a></p>*/}
                <p><a href="https://wa.me/919833243137" target="_blank">+91 98332 43137</a></p>
            </div>
            <div className={phoneCheck() ? "contact-mobile" : "contact"}>
                <div className="hr-text">
                    <hr className="hr"/>
                    <p> Email </p>
                    <hr className="hr"/>
                </div>
                <p><a href="mailto:natashadphotos@gmail.com">natashadphotos@gmail.com</a></p>
            </div>
        </div>
    );

    return(
        <div className="Contact">
            <Navbar1/>
            {contactInfo()}
            <Footer/>
        </div>
    )
};

export default Contact;
