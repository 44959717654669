import React from 'react';
import './Footer.scss';

const Footer = () => {

    return(
        <div className="Footer">
            <div>All images © 2023 Natasha Desai.</div>
        </div>
    )
};

export default Footer;
