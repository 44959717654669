import React, {useRef} from 'react';
import Footer from "./Footer";
import Navbar from "./Navbar";
import Navbar1 from "./Navbar1";
import './Home.scss';
import {phoneCheck} from "./helpers/browserCheck";

const Home = () => {

    const scrollRef = useRef(null);


    const homeContent = () => {
        return(
            <div className="home-content">
                {photos.map((p,i) => imageContainer(p,i) )}
            </div>
        )
    };

    const imageContainer = (p,i) => {
        return(
            <div key={i} className="image-container">
                <img src={p.imgUrl} alt={p.alt} />
                {/*{p.caption ? <span className="caption">{p.caption}</span> : null}*/}
            </div>
        )
    };

    const photoGrid = () => {
        return(
            <div className="row">
                    <div className="column">
                        <img src="https://res.cloudinary.com/dv1wy5buq/image/upload/v1699242027/1_xg6564.jpg" />
                        <img src="https://res.cloudinary.com/dv1wy5buq/image/upload/v1699242028/2_tm1bly.jpg" />
                        <img src="https://res.cloudinary.com/dv1wy5buq/image/upload/v1699242028/3_jvxaf5.jpg" />
                        <img src="https://res.cloudinary.com/dv1wy5buq/image/upload/v1699242029/4_qeehwu.jpg" />
                        <img src="https://res.cloudinary.com/dv1wy5buq/image/upload/v1699242030/5_v45klf.jpg" />
                        <img src="https://res.cloudinary.com/dv1wy5buq/image/upload/v1699242029/6_zkge9q.jpg" />
                        <img src="https://res.cloudinary.com/dv1wy5buq/image/upload/v1699242030/7_ppqdem.jpg" />
                        <img src="https://res.cloudinary.com/dv1wy5buq/image/upload/v1699242031/8_smksmf.jpg" />
                        <img src="https://res.cloudinary.com/dv1wy5buq/image/upload/v1699242032/9_qhizz3.jpg" />
                        <img src="https://res.cloudinary.com/dv1wy5buq/image/upload/v1699245093/10_gfa9gm.jpg" />
                        <img src="https://res.cloudinary.com/dv1wy5buq/image/upload/v1699242035/11_f5m7qi.jpg" />
                        <img src="https://res.cloudinary.com/dv1wy5buq/image/upload/v1699242034/12_byjcvf.jpg" />
                        <img src="https://res.cloudinary.com/dv1wy5buq/image/upload/v1699242034/13_qoiit6.jpg" />
                        <img src="https://res.cloudinary.com/dv1wy5buq/image/upload/v1699242040/14_vvaqik.jpg" />
                        <img src="https://res.cloudinary.com/dv1wy5buq/image/upload/v1699242037/15_xjs0xo.jpg" />

                    </div>

                <div className="column">
                    <img src="https://res.cloudinary.com/dv1wy5buq/image/upload/v1699242037/16_c2sy7s.jpg" />
                    <img src="https://res.cloudinary.com/dv1wy5buq/image/upload/v1699242038/17_ghlhvp.jpg" />
                    <img src="https://res.cloudinary.com/dv1wy5buq/image/upload/v1699242038/18_e3d4xi.jpg" />
                    <img src="https://res.cloudinary.com/dv1wy5buq/image/upload/v1699242040/19_o6whxd.jpg" />
                    <img src="https://res.cloudinary.com/dv1wy5buq/image/upload/v1699242042/20_ov2eiu.jpg" />
                    <img src="https://res.cloudinary.com/dv1wy5buq/image/upload/v1699242043/21_hqwchr.jpg" />
                    <img src="https://res.cloudinary.com/dv1wy5buq/image/upload/v1699242042/22_i9oqes.jpg" />
                    <img src="https://res.cloudinary.com/dv1wy5buq/image/upload/v1699242047/23_obcm9b.jpg" />
                    <img src="https://res.cloudinary.com/dv1wy5buq/image/upload/v1699242051/24_vgqapy.jpg" />
                    <img src="https://res.cloudinary.com/dv1wy5buq/image/upload/v1699242044/25_v9woyo.jpg" />
                    <img src="https://res.cloudinary.com/dv1wy5buq/image/upload/v1699242047/26_o0um1j.jpg" />
                    <img src="https://res.cloudinary.com/dv1wy5buq/image/upload/v1699245094/27_kwfxvh.jpg" />
                    <img src="https://res.cloudinary.com/dv1wy5buq/image/upload/v1699242047/28_nzz61y.jpg" />
                    <img src="https://res.cloudinary.com/dv1wy5buq/image/upload/v1699242049/29_wlzy4g.jpg" />
                </div>

                {/*<div className="column">*/}
                {/*    <div className="item">*/}
                {/*        <img src="https://res.cloudinary.com/dv1wy5buq/image/upload/v1699218655/CEB17411-B533-41A5-84DC-FD4435CA9940_fjqs3o.jpg" />*/}
                {/*        <span>caption</span>*/}
                {/*    </div>*/}
                {/*    <div>*/}
                {/*        <img src="https://res.cloudinary.com/dv1wy5buq/image/upload/v1699218655/CEB17411-B533-41A5-84DC-FD4435CA9940_fjqs3o.jpg" />*/}
                {/*        <span>caption21</span>*/}
                {/*    </div>*/}

                {/*</div>*/}
            </div>
        )
    };

    const photoGridMobile = () => {
        return(
            <div className="row">
                <div className="column">
                    <img src="https://res.cloudinary.com/dv1wy5buq/image/upload/v1699242027/1_xg6564.jpg" />
                    <img src="https://res.cloudinary.com/dv1wy5buq/image/upload/v1699242037/16_c2sy7s.jpg" />
                    <img src="https://res.cloudinary.com/dv1wy5buq/image/upload/v1699242028/2_tm1bly.jpg" />
                    <img src="https://res.cloudinary.com/dv1wy5buq/image/upload/v1699242038/17_ghlhvp.jpg" />
                    <img src="https://res.cloudinary.com/dv1wy5buq/image/upload/v1699242028/3_jvxaf5.jpg" />
                    <img src="https://res.cloudinary.com/dv1wy5buq/image/upload/v1699242038/18_e3d4xi.jpg" />
                    <img src="https://res.cloudinary.com/dv1wy5buq/image/upload/v1699242029/4_qeehwu.jpg" />
                    <img src="https://res.cloudinary.com/dv1wy5buq/image/upload/v1699242040/19_o6whxd.jpg" />
                    <img src="https://res.cloudinary.com/dv1wy5buq/image/upload/v1699242030/5_v45klf.jpg" />
                    <img src="https://res.cloudinary.com/dv1wy5buq/image/upload/v1699242042/20_ov2eiu.jpg" />
                    <img src="https://res.cloudinary.com/dv1wy5buq/image/upload/v1699242029/6_zkge9q.jpg" />
                    <img src="https://res.cloudinary.com/dv1wy5buq/image/upload/v1699242043/21_hqwchr.jpg" />
                    <img src="https://res.cloudinary.com/dv1wy5buq/image/upload/v1699242030/7_ppqdem.jpg" />
                    <img src="https://res.cloudinary.com/dv1wy5buq/image/upload/v1699242042/22_i9oqes.jpg" />
                    <img src="https://res.cloudinary.com/dv1wy5buq/image/upload/v1699242031/8_smksmf.jpg" />
                    <img src="https://res.cloudinary.com/dv1wy5buq/image/upload/v1699242047/23_obcm9b.jpg" />
                    <img src="https://res.cloudinary.com/dv1wy5buq/image/upload/v1699242032/9_qhizz3.jpg" />
                    <img src="https://res.cloudinary.com/dv1wy5buq/image/upload/v1699242051/24_vgqapy.jpg" />
                    <img src="https://res.cloudinary.com/dv1wy5buq/image/upload/v1699245093/10_gfa9gm.jpg" />
                    <img src="https://res.cloudinary.com/dv1wy5buq/image/upload/v1699242044/25_v9woyo.jpg" />
                    <img src="https://res.cloudinary.com/dv1wy5buq/image/upload/v1699242035/11_f5m7qi.jpg" />
                    <img src="https://res.cloudinary.com/dv1wy5buq/image/upload/v1699242047/26_o0um1j.jpg" />
                    <img src="https://res.cloudinary.com/dv1wy5buq/image/upload/v1699242034/12_byjcvf.jpg" />
                    <img src="https://res.cloudinary.com/dv1wy5buq/image/upload/v1699245094/27_kwfxvh.jpg" />
                    <img src="https://res.cloudinary.com/dv1wy5buq/image/upload/v1699242034/13_qoiit6.jpg" />
                    <img src="https://res.cloudinary.com/dv1wy5buq/image/upload/v1699242047/28_nzz61y.jpg" />
                    <img src="https://res.cloudinary.com/dv1wy5buq/image/upload/v1699242040/14_vvaqik.jpg" />
                    <img src="https://res.cloudinary.com/dv1wy5buq/image/upload/v1699242049/29_wlzy4g.jpg" />
                    <img src="https://res.cloudinary.com/dv1wy5buq/image/upload/v1699242037/15_xjs0xo.jpg" />

                </div>

                {/*<div className="column">*/}
                {/*    <div className="item">*/}
                {/*        <img src="https://res.cloudinary.com/dv1wy5buq/image/upload/v1699218655/CEB17411-B533-41A5-84DC-FD4435CA9940_fjqs3o.jpg" />*/}
                {/*        <span>caption</span>*/}
                {/*    </div>*/}
                {/*    <div>*/}
                {/*        <img src="https://res.cloudinary.com/dv1wy5buq/image/upload/v1699218655/CEB17411-B533-41A5-84DC-FD4435CA9940_fjqs3o.jpg" />*/}
                {/*        <span>caption21</span>*/}
                {/*    </div>*/}

                {/*</div>*/}
            </div>
        )
    };

    return(
        <div className="Home">
            <Navbar1/>
            {phoneCheck() ? photoGridMobile() : photoGrid()}
            <Footer/>
        </div>
    )
};

export default Home;

export const photos = [
    {
        id: 1,
        imgUrl: "https://res.cloudinary.com/dv1wy5buq/image/upload/v1699218655/CEB17411-B533-41A5-84DC-FD4435CA9940_fjqs3o.jpg",
        alt: "twig at twilight",
        caption: "twilight"
    },
    {
        id: 2,
        imgUrl: "https://res.cloudinary.com/dv1wy5buq/image/upload/v1699218647/IMG_5916_s9otol.jpg",
        alt: "tree and a rock",
        caption: "tree and rock"
    },
    {
        id: 3,
        imgUrl: "https://res.cloudinary.com/dv1wy5buq/image/upload/v1699218644/D513A45B-5F9F-4C80-8735-1695A1A621B9_wwtddh.jpg",
        alt: "camera obscura",
        caption: ""
    }

];


////// Transition on scroll references

// 1. https://stackoverflow.com/questions/74881076/what-is-the-best-way-to-make-css-animation-working-with-scrolling-on-react
// 2. https://www.geeksforgeeks.org/how-to-trigger-animations-in-a-reactjs-app-based-on-the-scroll-position/
// 3. https://blog.hubspot.com/website/css-fade-in
