import React, {useState} from 'react';
import './PublishedWork.scss';
import { Document, Page } from 'react-pdf';
import Navbar1 from "./Navbar1";
import Footer from "./Footer";
import sos from './assets/Songs of Silence.pdf';
import o2y from './assets/An Ode to Yourself.pdf';
import lp from './assets/Leisure playback final.pdf';
import vbs from './assets/Vision Beyond Sight.pdf';
import {phoneCheck} from "./helpers/browserCheck";
import sos_img from './assets/sos_img.png';
import o2y_img from './assets/o2y_img.png';
import lp_img from './assets/lp_img.png';
import vbs_img from './assets/vbs_img.png';
import lp_img1 from './assets/Screen Shot 2024-03-04 at 3.51.54 PM.png';
import o2y_img1 from './assets/Screen Shot 2024-03-04 at 3.53.16 PM.png';
import sos_img1 from './assets/Screen Shot 2024-03-04 at 3.53.44 PM.png';
import vbs_img1 from './assets/Screen Shot 2024-03-04 at 3.54.22 PM.png';

const PublishedWork = () => {

    const mobileView = () => {
        return(
            <div className="pdf-container-mobile">

                <div className="title">Published Work</div>
                {/*<a href={sos}>*/}
                {/*    <div className="pdf">*/}
                {/*        <div className="img-container"><img src={sos_img} /></div>*/}
                {/*        <p>Songs of Silence</p>*/}
                {/*    </div>*/}
                {/*</a>*/}

                {/*<a href={o2y}>*/}
                {/*    <div className="pdf">*/}
                {/*        <div className="img-container"><img src={o2y_img} /></div>*/}
                {/*        <p>An Ode to Yourself</p>*/}
                {/*    </div>*/}
                {/*</a>*/}

                {/*<a href={lp}>*/}
                {/*    <div className="pdf">*/}
                {/*        <div className="img-container"><img src={lp_img} /></div>*/}
                {/*        <p>Capturing Vishnu</p>*/}
                {/*    </div>*/}
                {/*</a>*/}

                {/*<a href={vbs}>*/}
                {/*    <div className="pdf">*/}
                {/*        <div className="img-container"><img src={vbs_img} /></div>*/}
                {/*        <p>Vision Beyond Sight</p>*/}
                {/*    </div>*/}
                {/*</a>*/}



                <div className="img-container">
                    <img src={sos_img1} />
                    <a href={sos} className="sos"><p>Songs of Silence</p></a>
                </div>

                <div className="img-container">
                    <img src={o2y_img1} />
                    <a href={o2y} className="o2y"><p>An Ode to Yourself</p></a>
                </div>

                <div className="img-container">
                    <img src={lp_img1} />
                    <a href={lp} className="lp"><p>Capturing Vishnu</p></a>
                </div>

                <div className="img-container">
                    <img src={vbs_img1} />
                    <a href={vbs} className="vbs"><p>Vision Beyond Sight</p></a>
                </div>

                </div>
        )
    };

    const webView = () => (
        <div className="pdf-container">
            <div className="pdf">
                <div className="title">Songs of Silence</div>
                <embed
                    src={`${sos}#toolbar=1`}
                    type="application/pdf"
                />
            </div>

            <div className="pdf">
                <div className="title">An Ode to Yourself</div>
                <embed
                    src={`${o2y}#toolbar=1`}
                    type="application/pdf"
                />
            </div>

            <div className="pdf">
                <div className="title">Capturing Vishnu</div>
                <embed
                    src={`${lp}#toolbar=1`}
                    type="application/pdf"
                />
            </div>

            <div className="pdf">
                <div className="title">Vision Beyond Sight</div>
                <embed
                    src={`${vbs}#toolbar=1`}
                    type="application/pdf"
                />
            </div>
        </div>
    )

    return(
        <div className="PublishedWork">
            <Navbar1/>

            {phoneCheck() ? mobileView() : webView()}

            <Footer/>
        </div>
    )
};

export default PublishedWork;
