import React from 'react';
import './About.scss';
import Navbar1 from "./Navbar1";
import Footer from "./Footer";
import {phoneCheck} from "./helpers/browserCheck";

const About = () => {
    return(
        <div className="About">
            <Navbar1/>
            <div className={phoneCheck() ? "about-container-mobile" : "about-container"}>
                <img src="https://res.cloudinary.com/dv1wy5buq/image/upload/v1699224581/IMG_6522_nbywcu.jpg"/>
                <div className="about-copy-container">
                    <p>Natasha Desai is an independent photographer and educator based in Mumbai. She holds a Post-Graduate Diploma in Mass Communication from SCM Sophia.</p>
                    <p>She worked at Better Photography, where she wrote about photography and curated visuals for over 100+ feature stories, exhibitions, and photography competitions.</p>
                    <p>She is passionate about giving back to society and manages the photography needs of Dishaa Pratishthaan, a women-led non-profit organisation. Since 2021, she has been teaching photography to postgraduate students at SCM Sophia where she guides them through photo-based assignments, photowalks, and curates their work for their public events.</p>
                    <p>Her work has been published in <i>Better Photography</i>, <i>Verve</i>, <i>Hindustan Times</i>, and <i>TimeOut Mumbai</i>.</p>
                </div>
            </div>
            <Footer/>
        </div>
    )
};

export default About;
